import type { IndustryOptionsObject } from "../../translation-keys/industry-option-keys.js";

const FormIndustryOptions: IndustryOptionsObject = {
  "Active Pharmaceutical Ingredients": "医薬品有効成分",
  "Aerospace": "航空宇宙",
  "Animal Health": "動物の健康",
  "Automotive": "自動車",
  "Banking/Finance/Insurance": "銀行/金融/保険",
  "Biotechnology - Med Device": "バイオテクノロジー - 医療機器",
  "Biotechnology - Pharma": "バイオテクノロジー - 医薬品",
  "Blood & Biologics": "血液・生物製剤",
  "Chemicals": "化学薬品",
  "Clinical Research": "臨床研究",
  "Combination Device": "複合医療機器",
  "Combination Pharma": "複合製剤",
  "Communications": "通信",
  "Computer Equipment/Peripherals": "コンピューター機器/周辺機器",
  "Construction/Architecture/Engineering": "建設/建築/エンジニアリング",
  "Consulting Services": "コンサルティングサービス",
  "Contract Manufacturing - Med Device": "受託製造 - 医療機器",
  "Contract Manufacturing - Pharma": "受託製造 - 医薬品",
  "Contract Research Organization (CRO)": "受託研究機関（CRO）",
  "Cosmetics": "化粧品",
  "Diagnostics - Medical": "診断 - 医療",
  "Dietary Supplements": "栄養補助食品",
  "Distribution": "流通",
  "Education": "教育",
  "Electronics": "電子機器",
  "Energy": "エネルギー",
  "Environmental": "環境",
  "Food": "食品",
  "General Manufacturing": "一般製造",
  "Government": "政府機関",
  "Healthcare": "ヘルスケア",
  "Hospital": "病院",
  "Laboratory - Biologics": "研究所 - 生物学",
  "Laboratory - ISO": "研究所 - ISO",
  "Laboratory - MD": "研究所 - 医療機器",
  "Laboratory - Other": "研究所 - その他",
  "Lawyer / Law Firm": "弁護士/法律事務所",
  "Life Science Supplier": "ライフサイエンスサプライヤー",
  "Media": "メディア",
  "Medical Device": "医療機器",
  "Medical Marijuana": "医療用大麻",
  "Nuclear": "原子力",
  "Oil & Gas / Mining": "石油＆ガス/鉱業",
  "Packaging": "包装",
  "Pharmaceutical": "医薬品",
  "Publishing": "パブリッシング",
  "Retail": "リテール",
  "Software/Hardware Supplier": "ソフトウェア/ハードウェアサプライヤー",
  "Student/University": "大学",
  "Technology": "テクノロジー",
  "Transportation/Logistics": "輸送/物流",
  "Travel/Hospitality/Restaurant": "旅行/ホスピタリティ/レストラン"
};

export default FormIndustryOptions;